import React from 'react'
import { colors } from '../assets'

const Container = ({ children, background = colors.TETRA_WHITE }) => (
  <div style={{ background: background }}>
    {children}
  </div>
)

export default Container
